import { useContext } from "react";
import AppContext from "./AppContext";
import menuList from "../resources/menuList";
import Link from "next/link";
import helper from "../lib/helper";
export default function Sidebar({ children }) {
  const themeContext = useContext(AppContext);
  return (
    <>
      <div id="layoutSidenav_nav">
        <nav
          className="sb-sidenav accordion sb-sidenav-light"
          id="sidenavAccordion"
        >
          <div className="sb-sidenav-menu border">
            <div className="nav">
              {menuList.map((item) => (
                <Link href={item.href} key={`menus-${item.key}`} className="nav-link">
                  <div className="sb-nav-link-icon">
                    <i className={`${item.icon}`}></i>
                  </div>
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
          <div className="sb-sidenav-footer">
            <div className="small">Logged in as:</div>
            {helper.user?.full_name}
          </div>
        </nav>
      </div>
    </>
  );
}
