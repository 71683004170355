export default function Footer({ children }) {
  return (
    <>
      <footer className="sticky-footer bg-light py-2">
        <div className="container">
          <div className="copyright text-center">
            <span>Copyright &copy; Pinknblu infomedia pvt. ltd.</span>
          </div>
        </div>
      </footer>
    </>
  )
}