import React, { useState } from "react";
import Layout from "../components/layout";
import "/resources/app.scss";
import AppContext from "../components/AppContext";

export default function MyApp({ Component, pageProps }) {
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const themes = { sidebarStatus, setSidebarStatus };
  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ||
    ((page) => {
      return <Layout>{page}</Layout>;
    });

  return (
    <AppContext.Provider value={themes}>
      {getLayout( <Component {...pageProps} />)}
    </AppContext.Provider>
  );
}
