import { isNaN, isNull } from "lodash";
import moment from "moment";
import Swal from "sweetalert2";



//sweetalert
export function message(text, icon = "success") {
    return Swal.fire({
        icon,
        text
    })
}

export function toast(text, icon = "success") {
    return Swal.fire({
        icon,
        text,
        toast: true,
        showConfirmButton: false,
        position: "top-right",
        timer: 5000,
        timerProgressBar: true
    })
}
export function confirm(text, icon = "success") {
    return Swal.fire({
        icon,
        text,
        buttons: true,
        showCancelButton: true
    })
}

export function slug(title) {
    return title.toLowerCase().trim().replace(/[^a-z0-9\s-]/g, '').replace(/ /g, "-")
}

//Date 2023-05-11T14:01:18.000Z
function dateOnly(date) {
    return moment.utc(date).format("DD-MM-Y");
}
function timeOnly(date) {
    return moment.utc(date).format("HH:mm A");
}

function format(date, format = "DD-MM-YYYY HH:mm:ss") {
    return moment(date, format).format(format);
}

//currency
function currencyFormat(currency) {
    if (!currency || isNull(currency) || isNaN(currency)) {
        return "---";
    }
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0
    }).format(currency);
}


export default {
    sweetalert: { message, toast, confirm },
    text: { slug },
    date: { dateOnly, timeOnly, format },
    currency: { format: currencyFormat }
}


