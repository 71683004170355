// import 'components/layout.js'

import Navbar from "./navbar";
import Footer from "./footer";
import Sidebar from "./sidebar";
import AppContext from './AppContext';
import { useContext, useState } from "react";

export default function Layout({children }) {
  const themeContext = useContext(AppContext);
  return (
    <>
      <div className={`sb-nav-fixed ${themeContext.sidebarStatus && 'sb-sidenav-toggled'}`}>
        <Navbar />
        <div id="layoutSidenav">
          <Sidebar />
          <div id="layoutSidenav_content">
            <main>
                {children}
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </> 
  );
}
